import Vue from 'vue'
import App from './App.vue'
// import './style/reset.less'

import qs from 'qs'
import request from "axios"


import router from './router'

import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import requestApi from "./api/api"
import tools from "./utils/tool"
import 'amfe-flexible'
import wxSecret from "./utils/wxSecret"

import moment from 'moment'

Vue.prototype.$axiosGet = requestApi.axiosGet
Vue.prototype.$axiosPost = requestApi.axiosPost
Vue.prototype.$axiosMsg = requestApi.axiosMsg
Vue.prototype.$weChat = requestApi.weChat

Vue.prototype.getCurrentTime = tools.getCurrentTime
Vue.prototype.differentTime=tools.differentTime

Vue.prototype.downloadFileFn=tools.downloadFile
Vue.prototype.downloadUrlFn=tools.downloadUrl



Vue.prototype.$wxSecret=wxSecret
// 自动按需引入vant组件库



Vue.prototype.$moment=moment


Vue.prototype.$qs = qs
Vue.prototype.$axios = request
Vue.config.productionTip = false


Vue.use(ElementUI);


var nowTemp = new Date().getTime() //获取当前时间戳
var tomorrowTemp = new Date(moment().add(1, 'days').format().substr(0,10)+' 00:00:00').getTime() //获取今天24：00的时间戳
var residueTemp = tomorrowTemp - nowTemp //距离当天24：00的时间戳
console.log( '距离次日0点的时间戳' +residueTemp)
//执行定时任务
setTimeout(()=>{
  localStorage.setItem('zero',1)
  console.log( new Date())

   //次天0点 执行每天24;00 刷新
   setInterval(() => {
     console.log( new Date())
      localStorage.setItem('zero',1)
     },1000*60*60*24);

},residueTemp)



// 将路由对象注入到new vue实例中
new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app')
