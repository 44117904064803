import axios from 'axios'
import router from "../router"
import qs from 'qs'
import url from "./url"
import { Message } from 'element-ui';

//接口地址
let baseURL=url.baseURL;
let baseURL3=url.baseURL3 ;

let config = {
    baseURL: baseURL,
    
  }
const requestAxios = axios.create(config)
  
/* 请求拦截器 */
requestAxios.interceptors.request.use(
  
  config => {

    if(config.url=='/api/User/LoginFromForm' || config.url=='/api/Message/GetCheckedCodeFromForm' ||config.url=='/api/User/WeChatLogin'){
     
    }else{
      config.headers.Authorization = 'Bearer '+localStorage.getItem('token');  //添加验证头

    }

    return config;
  },
  err => Promise.reject(err)
);

 
//响应拦截
requestAxios.interceptors.response.use(
 
   response=>{
    //对响应数据做点什么

      
    if(response.config.url=='/api/User/LoginFromForm' || response.config.url=='/api/Message/GetCheckedCodeFromForm' ||response.config.url=='/api/User/WeChatLogin'){
     
    }else{
     const tokenStartTime= Number(localStorage.getItem('tokenStartTime'))

    const timeOver = 30 * 24 * 3600 * 1000
    let currentTime=  new Date().getTime()
    if(currentTime-tokenStartTime>timeOver){

      localStorage.removeItem('token')
      localStorage.removeItem('tokenStartTime')
      router.replace({path: '/'});
      
      Message.warning('登录状态已过期，请重新登录！！');
    }
  }
    return response
  },
  error=>{
      console.log(error)
    //对响应错误做点什么
    try {
      if (error.response) {
        switch (error.response.status) {
          case 401://token过期，清除它,清除token信息并跳转到登录页面
          localStorage.removeItem('token')
                
        //   Message.warning('登录状态已失效，请重新登录！！');
          router.replace({path: '/'});
            return;
          case 403://权限
          localStorage.removeItem('token')
        //   Message.warning('登录状态已失效，请重新登录！！');

          router.replace({path: '/'});
            return;
        }
      }

    }
    catch (e) {
    }

  }

)

const http = {
    get(url='',params={},header={}){
      let head=header

      // head.authorization = 'Bearer '+localStorage.getItem('token'); 
      return new Promise((resolve, reject) => {
        requestAxios({
         url:url,
          params,
          headers:head,
          method: 'GET'
        }).then(res => {
          resolve(res.data)
          return res
        }).catch(error => {
         
          reject(error)
        })
      })
    },
    post(url='',params={},header={},dataType){
      let head=header
     
      return new Promise((resolve, reject) => {
        requestAxios({
          url,
          data:dataType?qs.stringify(params):params,
          headers:head,
          method: 'POST'
        }).then(res => {
          resolve(res.data)
          return res
        }).catch(error => {
          reject(error)
        })
      })
    },
    baseURL3:baseURL3
      
    
  }
  
  export default http
