    //接口地址
    // let baseURL="https://test.api.antgv.com";//测试
    let baseURL="https://api.antgv.com/";//正式

    
//微信授权的重定向地址
    let baseURL3="https://mdemand.antgv.com/#/loginLast" //正式
  // let baseURL3="https://test.demand.antgv.com/#/loginLast?"; //测试

//移动端跳转地址
  // let baseMobile="https://test.demand.antgv.com";//测试
  let baseMobile="https://mdemand.antgv.com";//正式

    export default{
        baseURL,
        baseURL3,
        baseMobile
    }
