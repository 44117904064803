import http from  "./http"
import store from '../store'
import urlencode from "urlencode";
let request={}

request.axiosGet = ((url,params,header)=>{
    return http.get(url, params,header)
})

request.axiosPost =((url,params,header,dataType)=>{
    return http.post(url, params,header,dataType)

})
request.axiosMsg =(()=>{
    
   return http.get('/api/User/GetUserInfo').then(res=>{
        if(res.Tag==1){
     
            store.commit('setCompanyName',res.Data.CompanyName)
            store.commit('setCompanyCode',String(res.Data.CompanyCode))
            store.commit('setCompanyId',String(res.Data.Id))
           store.commit('setBigIndustryType',String(res.Data.BigIndustryType))
           store.commit('setCityId',String(res.Data.CityId))
           store.commit('setCountyId',String(res.Data.CountyId))
           store.commit('setProvinceId',String(res.Data.ProvinceId))
           store.commit('setElectricalProperty',String(res.Data.ElectricalProperty))
           store.commit('setSmallIndustryType',String(res.Data.SmallIndustryType))
           
           store.commit('setremark',"")
           
           if(res.Data.Remark){

            store.commit('setremark',res.Data.Remark)

        }
      
}
    })
})
request.weChat=((datas)=>{
  

        
        // var appid=' wx12822170aa36aafd' //测试的appid
       
    
        
        //  var local = window.location.href+'loginLast'


         
        // 微信拉起用户授权直接登录 123
        // 手机静默授权登录 321
        let type='',local = http.baseURL3,appid='wxd3e5989e4f010739'
        if(datas==321){
            type='snsapi_base'

        }else{
            type='snsapi_userinfo'
        }
      let link=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlencode(local)}&response_type=code&scope=${type}&state=${datas}#wechat_redirect`
      
        window.location.href =link

 

})
export default request