import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    CompanyName:"",
    CompanyCode:"",
    BigIndustryType:"",
    CityId:"",
    CountyId:"",
    ProvinceId:"",
    ElectricalProperty:"",
    SmallIndustryType:"",
    remark:"",
    CompanyId:''
  },
  getters: {
    GETCompanyName:state => state.CompanyName,
    GETCompanyCode:state => state.CompanyCode,
    GETCompanyId:state => state.CompanyId,
    GETBigIndustryType:state => state.BigIndustryType,
    GETCityId:state => state.CityId,
    GETCountyId:state => state.CountyId,
    GETProvinceId:state => state.ProvinceId,
    GETElectricalProperty:state => state.ElectricalProperty,
    GETSmallIndustryType:state => state.SmallIndustryType,
    GETremark:state => state.remark
  },
  mutations: {
    setCompanyName(state,e){
      state.CompanyName=e

    },
    setCompanyCode(state,e){
      state.CompanyCode=e
    },
    setCompanyId(state,e){
      state.CompanyId=e
    },
    setBigIndustryType(state,e){
      state.BigIndustryType=e
    },
    setCityId(state,e){
      state.CityId=e
    },
    setCountyId(state,e){
      state.CountyId=e
    },
    setProvinceId(state,e){
      state.ProvinceId=e
    },
    setElectricalProperty(state,e){
      state.ElectricalProperty=e
    },
    setSmallIndustryType(state,e){
      state.SmallIndustryType=e
    },
    setremark(state,e){
      state.remark=e
    }
  },
  actions: {
  },
  modules: {
  }
})
