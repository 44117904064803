
let assembly={}

assembly.getCurrentTime = ((separator) =>{
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth()+1;
    let dd = new Date().getDate();
    let hh = new Date().getHours();
    let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
    let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();

  let  gettime = yy+separator+mm+separator+dd+' '+hh+':'+mf+':'+ss;

    return gettime;


})

assembly.differentTime = (faultDate, completeTime)=>{
    var stime = Date.parse(new Date(faultDate));//获得开始时间的毫秒数
    var etime = Date.parse(new Date(completeTime));//获得结束时间的毫秒数
    var usedTime = etime - stime; //两个时间戳相差的毫秒数
    var days = Math.floor(usedTime / (24 * 3600 * 1000));
    //计算出小时数
    var leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000));//将剩余的毫秒数转化成小时数
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000));//将剩余的毫秒数转化成分钟
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000);//计算分钟数后剩余的毫秒数
    var seconds = Math.floor(leave3/1000);//将剩余的毫秒数转化成秒数

    var dayStr =( days<=0?'00':days<10&&days>0?'0'+days:days)+'天';
    var hoursStr =( hours<=0?'00':hours<10&&hours>0?'0'+hours:hours) +'时';
    var minutesStr = ( minutes<=0?'00':minutes<10&&minutes>0?'0'+minutes:minutes)+'分';
  
    var time = dayStr + hoursStr + minutesStr 
    return time;
    }
    
    //    下载文件
    assembly.downloadFile=(url,filename)=>{

      let blob = new Blob([url]); // 将返回的数据通过Blob的构造方法，创建Blob对象
      if ('msSaveOrOpenBlob' in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, filename); // 针对浏览器
      } else {
        const elink = document.createElement('a'); 
        elink.download = filename; //文件名
        elink.style.display = 'none';
        // 创建一个指向blob的url，这里就是点击可以下载文件的根结
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); 
        document.body.removeChild(elink); 
      }
    
      }
      assembly.downloadUrl=(urlData,filename)=>{
        let request = new XMLHttpRequest();
        request.open("GET", urlData, true);
        request.responseType = "blob";
        request.onload = ()=> {
          let url = window.URL.createObjectURL(request.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        };
        request.send();
      }

export default assembly