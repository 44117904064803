import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path:'/',
    meta:{
        title:'登录'
    },
    component:(resolve)=>require(['../views/login/login.vue'],resolve)
},
{
  path:'/login',
  meta:{
      title:'登录'
  },
  component:(resolve)=>require(['../views/login/login.vue'],resolve)
},
{
  path:'/layout',
  meta:{
      title:'容器'
  },
  component:(resolve)=>require(['../views/layout/layout.vue'],resolve),
  children: [
    {
      path:'/information',
      meta:{
          title:'企业信息'
      },
      component:(resolve)=>require(['../views/layout/information/information.vue'],resolve)
    },
    {
      path:'/demandResponse',
      meta:{
          title:'需求响应'
      },
      component:(resolve)=>require(['../views/layout/demandResponse/demandResponse.vue'],resolve)
    },
    
  
  ]
}
]

const router = new VueRouter({
  routes
})

export default router
