
<template>

  <div >
    <!-- 设置路由页面挂载点 -->
    <router-view name="blank"></router-view>
    <router-view></router-view>
  </div>
</template>

<script>

import url from './api/url'
export default {
 
  data() {
    return {};
  },
  
        mounted() {
            if(this.isMobile()){
  
                 window.open(url.baseMobile,'_self')
              }
 

        },
        methods:{
            isMobile() {
           
                return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            },
        },

};
</script>

<style lang="scss">
 body{
  margin: 0;
  padding: 0;
 }

.submit_btn{
  border-radius: 1.5px;
  color: white;
  text-align: center; 
  padding:  4px;
  background: #005fff;
  font-size: 4px;
   
 
}
.flex_end{
  display: flex;
  justify-content: flex-end;
  align-items:center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.el-message__icon{
  font-size: 15px;
}
.color_red{
  color: red !important;
}
.color_green{
  color: #07C160 !important;
}
.color_blue{
  color: #498FFB !important;
}
.color_lightgray{
  color: #808992 !important;
}

</style>