import  CryptoJS from 'crypto-js'

//let base64="wxd3e5989e4f010739"
const key = CryptoJS.enc.Utf8.parse("MAYIJINGU1231231");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('MAYIJINGU1231231');
export default ({
    decrypt(){

        //let srcs = CryptoJS.enc.Utf8.parse("1D06E4599C6F69909F8044E26F6FCDC3DBB55F13A9C4135F0AC803325D833B51");
       let encryptedHexStr = CryptoJS.enc.Hex.parse("04CF5F982321AC3960E86BA930CBF5DED02AF60BD67F31BE0CDF0B5C672BBB1A");
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();



    }

})